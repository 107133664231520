import React from 'react'
import { DiCode } from 'react-icons/di'
import { RiChat4Line, RiChatCheckLine } from 'react-icons/ri'
import { HiChartBar, HiOutlineClipboardCheck } from 'react-icons/hi'
import { CgFileDocument } from 'react-icons/cg'
import { VscKey } from 'react-icons/vsc'
import { IoExitOutline } from 'react-icons/io5'
import { AiOutlineAudit } from 'react-icons/ai'
import { MdWebhook } from 'react-icons/md'
import { classNames } from 'util/shared'

const icons = [
  { page: 'disclaimer', icon: <RiChat4Line size={16} /> },
  { page: 'style', icon: <RiChatCheckLine size={20} /> },
  { page: 'tags', icon: <DiCode size={28} /> },
  { page: 'documents', icon: <CgFileDocument size={18} /> },
  { page: 'terms', icon: <CgFileDocument size={18} /> },
  { page: 'policy', icon: <HiOutlineClipboardCheck size={22} /> },
  { page: 'installation', icon: <VscKey size={18} /> },
  { page: 'optout', icon: <IoExitOutline size={20} /> },
  { page: 'consent-log', icon: <DiCode size={28} /> },
  { page: 'dashboard', icon: <HiChartBar size={28} /> },
  { page: 'audit', icon: <AiOutlineAudit size={20} /> },
  { page: 'webhooks', icon: <MdWebhook size={20} /> }
]

function returnIcon(icons: any[], param: any) {
  const icon = icons.find((icon: { page: any }) => icon.page === param)
  return icon?.icon
}

interface PagesHeaderProps {
  page?: string
  title: string
  subtitle: string | React.ReactNode
  button?: React.ReactNode
  className?: string
}

export function PagesHeader({ ...props }: PagesHeaderProps) {
  const { title, subtitle, page, button, className } = props

  return (
    <div
      className={classNames(
        'px-10 dark:text-white flex justify-between items-center',
        className
      )}
    >
      <div className="flex items-center gap-5">
        {page && (
          <div className="w-12 h-12 text-primary rounded-full flex justify-center items-center bg-white-200 dark:bg-gray">
            {returnIcon(icons, page)}
          </div>
        )}
        <div>
          <div className="flex items-center font-black text-xl">{title}</div>
          <div className="text-gray-200">{subtitle}</div>
        </div>
      </div>
      <div>{button}</div>
    </div>
  )
}
