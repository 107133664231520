import { Input } from 'components/shared/inputs'
import { Select } from 'components/shared/select/select'
import { useTranslation } from 'next-i18next'
import { useRequestPage } from 'hooks/useRequestPage'
import { AccordionContentProps, FormData } from 'models/request-page'

export const GeneralInfoContent = ({
  formData,
  setFormData
}: AccordionContentProps) => {
  const { t } = useTranslation()
  const { dpoUsers } = useRequestPage()

  const handleFormDataChange = <K extends keyof FormData>(
    key: K,
    value: FormData[K]
  ) => {
    setFormData(prev => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <div className="flex flex-col gap-4 p-5 w-full">
      <Input
        label={t('createRequestPage.accordions.generalInfo.pageNameInput')}
        placeholder={t(
          'createRequestPage.accordions.generalInfo.pageNamePlaceholder'
        )}
        maxLength={50}
        value={formData.name}
        events={{
          onChange: (e: any) => {
            handleFormDataChange('name', e.value)
          }
        }}
      />
      <Select
        label={t('createRequestPage.accordions.generalInfo.dpoSelect')}
        containerClasses={'max-w-[350px]'}
        params={dpoUsers}
        value={formData.dpoUserId}
        firstChangeDisabled
        onChange={e => {
          handleFormDataChange('dpoUserId', e.value as string)
        }}
      />
    </div>
  )
}
