import { HiOutlineTemplate } from 'react-icons/hi'
import { ModalWrapper } from './modal-wrapper'
import { useTranslation } from 'next-i18next'
import { useRef, useState } from 'react'
import { SpinLoading } from '../loading/spin-loading'
import appConfig from 'config/app-config'

interface IframeModalProps {
  src: string
  messageBody: any
  open: boolean
  handleClose: () => void
}

export function RequestsIframeModal({
  src,
  open,
  handleClose,
  messageBody
}: IframeModalProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  async function handleLoad() {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: 'config',
          message: messageBody
        },
        appConfig.hubUrl
      )
    }
    setIsLoading(false)
  }

  return (
    <ModalWrapper
      modalOpen={open}
      setModalOpen={handleClose}
      headerProps={{
        icon: <HiOutlineTemplate className="text-primary" size={30} />,
        title: t('modal.iframeModal.title'),
        subtitle: t('modal.iframeModal.subTitle')
      }}
      modalSize={'large'}
    >
      <div className="h-[70vh] rounded-md border border-gray-100 dark:border-gray-400 p-2 flex justify-center">
        {isLoading && (
          <div className="h-full w-full flex justify-center items-center">
            <SpinLoading />
          </div>
        )}
        <iframe
          ref={iframeRef}
          onLoad={handleLoad}
          src={src}
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </ModalWrapper>
  )
}
