import { useTranslation } from 'next-i18next'
import { classNames } from 'util/shared'
import { BodyText } from '../typography'

type StatusRequestBadgeProps = {
  status: string
}

export function StatusRequestBadge({ status }: StatusRequestBadgeProps) {
  const { t } = useTranslation()

  const statusOptions = [
    {
      name: t('org.privacyPortal.requestDashboard.table.new'),
      value: 'new',
      color: 'bg-lightblue'
    },
    {
      name: t('org.privacyPortal.requestDashboard.table.processing'),
      value: 'processing',
      color: 'bg-orange'
    },
    {
      name: t('org.privacyPortal.requestDashboard.table.done'),
      value: 'done',
      color: 'bg-primary'
    }
  ]

  const currentStatus = statusOptions.find(it => it.value === status)

  return (
    <div className="flex gap-2 items-center">
      <span
        className={classNames(currentStatus?.color, 'rounded-full w-3 h-3')}
      />
      <BodyText>{currentStatus?.name}</BodyText>
    </div>
  )
}
