import { useMutation, useQuery } from '@apollo/client'
import { useStorage } from 'contexts/storage-context'
import {
  GET_USER_REQUEST_DETAILS,
  GET_USER_REQUESTS,
  GET_USER_REQUESTS_METRICS,
  SET_USER_REQUEST_STATUS
} from 'queries/user-requests'
import { RawUserRequestsList, UserRequestsProps } from 'models/user-requests'

export function useUserRequests({
  page = 0,
  id,
  status,
  eventType,
  startDate,
  endDate
}: UserRequestsProps) {
  const { activeOrganizationDetails } = useStorage()
  const [getUserRequestsMetricsMutation] = useMutation(
    GET_USER_REQUESTS_METRICS
  )
  const [getUserRequestDetailsMutation] = useMutation(GET_USER_REQUEST_DETAILS)
  const [setUserRequestStatusMutation] = useMutation(SET_USER_REQUEST_STATUS)

  const filterById = id ? { id: { _eq: id } } : {}
  const filterByStatus =
    status && status !== 'all' ? { status: { _eq: status } } : {}
  const filterByEventTypeId =
    eventType && eventType !== 'all'
      ? { event_type_id: { _eq: eventType } }
      : {}
  const filterByDate =
    startDate && endDate
      ? { created_at: { _gte: startDate, _lte: endDate } }
      : {}

  const userRequestsFilter = {
    requests_page_master: {
      organization_id: { _eq: activeOrganizationDetails?.id }
    },
    ...filterById,
    ...filterByStatus,
    ...filterByEventTypeId,
    ...filterByDate
  }

  const LIMIT = 10

  const userRequestsVariables = {
    offset: page * LIMIT,
    ...(Object.entries(userRequestsFilter).length && {
      where: userRequestsFilter
    })
  }

  const { data: userRequestsData, loading: userRequestsLoading } =
    useQuery<RawUserRequestsList>(GET_USER_REQUESTS, {
      fetchPolicy: 'network-only',
      skip: !activeOrganizationDetails?.id,
      variables: userRequestsVariables
    })

  async function getUserRequestDetails(requestId: string) {
    const result = await getUserRequestDetailsMutation({
      variables: {
        requestId
      }
    })
    return result?.data?.get_request_details
  }

  async function getUserRequestsMetrics(
    startDate: Date | null,
    endDate: Date | null
  ) {
    const result = await getUserRequestsMetricsMutation({
      variables: {
        organizationId: activeOrganizationDetails?.id ?? '',
        startDate: startDate ?? '',
        endDate: endDate ?? ''
      }
    })
    return result?.data?.get_opt_out_metrics
  }

  async function setUserRequestStatus(requestIds: string[], newStatus: string) {
    const result = await setUserRequestStatusMutation({
      refetchQueries: [
        {
          query: GET_USER_REQUESTS,
          variables: userRequestsVariables
        }
      ],
      variables: {
        requestIds,
        newStatus
      }
    })
    return result?.data?.set_user_request_status
  }

  const loading = userRequestsLoading

  return {
    list: userRequestsData?.requests_page_user_request ?? [],
    loading,
    getUserRequestsMetrics,
    getUserRequestDetails,
    setUserRequestStatus
  }
}
