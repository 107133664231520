import React from 'react'
import { classNames } from 'util/shared'

interface TypographyProps {
  className?: string
  children?: any
  fontWeight?: FontWeightTypes
}

const sharedClasses = 'text-gray dark:text-white'

export type FontWeightTypes = 'light' | 'normal' | 'bold' | 'medium'

export function BigTitle({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <h1
      className={classNames(
        sharedClasses,
        'text-5xl',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </h1>
  )
}

export function Advert({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <h1
      className={classNames(
        sharedClasses,
        'text-4xl',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </h1>
  )
}

export function H1({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <h1
      className={classNames(
        sharedClasses,
        'text-[20px]',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </h1>
  )
}

export function H2({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <h2
      className={classNames(
        sharedClasses,
        'text-[18px]',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </h2>
  )
}

export function H3({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <h3
      className={classNames(
        sharedClasses,
        'text-[16px]',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </h3>
  )
}

export function Paragraph({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <p
      className={classNames(
        sharedClasses,
        'text-base ',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </p>
  )
}

export function BodyText({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <p
      className={classNames(
        sharedClasses,
        'text-base',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </p>
  )
}

export function SmallText({
  className,
  children,
  fontWeight = 'normal'
}: TypographyProps) {
  return (
    <p
      className={classNames(
        sharedClasses,
        'text-sm',
        `font-${fontWeight}`,
        className
      )}
    >
      {children}
    </p>
  )
}
