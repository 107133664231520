import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import i18next from 'i18next'

import { useStorage } from 'contexts/storage-context'
import { ADD_EDIT_REQUESTS_PAGE, GET_DPO_USERS, GET_REQUEST_PAGE_BY_ID, GET_REQUEST_PAGE_DEFAULT } from 'queries/privacy-page'
import { useDocuments } from './useDocuments'
import { RawListDocumentProps } from 'models/documents'
import { DocumentTypes } from 'components/shared/document-management'
import { documentTypes } from 'pages/org/[organizationPathname]/documents'
import { DefaultTexts, DpoUsersList, FormData, Requests } from 'models/request-page'
import { LanguagesTypes } from 'util/enums'

interface FormDataParam extends Omit<Partial<FormData>, 'style'> {
  style: string
}

const initialData: FormData = {
  name: '',
  dpoUserId: '',
  languages: {
    fallback:
      i18next.language === 'en'
        ? LanguagesTypes.English
        : LanguagesTypes.Portuguese,
    languages: Object.values(LanguagesTypes)
  },
  texts: [],
  documents: {
    privacyMasterId: '',
    termsMasterId: '',
    cookiesMasterId: ''
  },
  extraFields: ['name', 'country'],
  requests: [],
  style: {
    logo: '',
    colors: {
      background: '#FFFFFF',
      form: '#21262D',
      title: '#00DD80',
      texts: '#FAFBFC',
      button: '#00DD80',
      buttonText: '#0D1117'
    },
    fonts: {
      title: {
        family: 'nunito',
        size: 22,
        lineHeight: 24
      },
      texts: {
        family: 'nunito',
        size: 16,
        lineHeight: 18
      },
      button: {
        family: 'nunito',
        size: 14,
        lineHeight: 24
      }
    },
    margins: {
      horizontally: 20,
      vertically: 20
    },
    rounded: {
      banner: 16,
      button: 6
    }
  }
}

export const useRequestPage = (requestPageId?: string) => {
  const { activeOrganizationDetails } = useStorage()
  const { listDocuments, listDocumentsLoading } = useDocuments({})
  const [defaultTexts, setDefaultTexts] = useState<DefaultTexts[]>([])
  const [defaultRequests, setDefaultRequests] = useState<Requests[]>([])
  const [initialFormData, setInitialFormData] = useState<FormData>(initialData)

  const [addEditRequestsPageMutation] = useMutation(ADD_EDIT_REQUESTS_PAGE)

  const { data: requestPageDefault, loading: requestPageDefaultLoading } = useQuery(GET_REQUEST_PAGE_DEFAULT, {
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (!requestPageDefaultLoading && requestPageDefault) {
      const { requests_page_default_texts, requests_page_default_requests } = requestPageDefault ?? {}
      setDefaultTexts(requests_page_default_texts)
      setDefaultRequests(requests_page_default_requests)

      setInitialFormData(prev => ({
        ...prev,
        texts: requests_page_default_texts.map((it: DefaultTexts) => ({
          language: it.language,
          title: it.texts.title,
          introduction: it.texts.introduction,
          docsTitle: it.texts.docsTitle
        })),
        requests: requests_page_default_requests
      }))
    }
  }, [requestPageDefault, requestPageDefaultLoading])


  const { data: dpoUsersList, loading: dpoUsersLoading } = useQuery(GET_DPO_USERS, {
    skip: !activeOrganizationDetails?.id,
    fetchPolicy: 'cache-first',
    variables: {
      organizationId: activeOrganizationDetails?.id
    }
  })

  const { data: requestPageById, loading: requestPageByIdLoading } = useQuery(GET_REQUEST_PAGE_BY_ID, {
    skip: !requestPageId,
    fetchPolicy: 'network-only',
    variables: {
      requestPageId
    }
  })

  async function addEditRequestsPage(
    options: FormDataParam,
    disclaimerId?: string,
    requestsPageId?: string
  ) {
    const result = await addEditRequestsPageMutation({
      variables: {
        organizationId: activeOrganizationDetails?.id,
        disclaimerId,
        requestsPageId,
        ...options
      }
    })
    return result?.data?.add_edit_requests_pages
  }

  function formatDpoUsersList(data: any): DpoUsersList[] {
    if (dpoUsersLoading || !data) {
      return [{
        name: '',
        value: ''
      }]
    }

    const { user_organization } = data?.organization[0] ?? {}
    const usersList = []
    for (const { users } of user_organization) {
      usersList.push(users)
    }
    return usersList?.map(user => ({
      name: user.email,
      value: user.id
    }))
  }

  function formatListDocuments(
    data: RawListDocumentProps | undefined,
    documentType: DocumentTypes
  ) {
    if (listDocumentsLoading) return

    const formattedData = data?.document_master
      ?.map(document => {
        return {
          id: document.id,
          document_type: document.document_type,
          document_name:
            document[documentTypes[document.document_type]]?.[0]?.document_name
        }
      })
      .filter(({ document_type }) => document_type === documentType)

    return formattedData ?? []
  }

  const privacyDocuments = formatListDocuments(listDocuments, 'privacy')?.map(
    ({ id, document_name }) => ({
      name: document_name,
      value: id
    })
  )
  const cookieDocuments = formatListDocuments(listDocuments, 'cookies')?.map(
    ({ id, document_name }) => ({
      name: document_name,
      value: id
    })
  )
  const termDocuments = formatListDocuments(listDocuments, 'terms')?.map(
    ({ id, document_name }) => ({
      name: document_name,
      value: id
    })
  )

  const loading = requestPageDefaultLoading || dpoUsersLoading || requestPageByIdLoading || listDocumentsLoading

  return { initialFormData, defaultTexts, defaultRequests, dpoUsers: formatDpoUsersList(dpoUsersList), privacyDocuments, cookieDocuments, termDocuments, requestPageById, loading, addEditRequestsPage }
}
