import { Paragraph, Button } from 'components'
import { motion } from 'framer-motion'
import Image from 'next/image'
import { fadeInUp } from 'public/animation/motion'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from 'util/shared'

import IconStarYellow from 'assets/imgs/star-yellow.svg'
import { useRouter } from 'next/router'
import { paths } from 'config/paths'

interface TooltipProps {
  children: ReactNode
  limited: boolean
  position?: 'left-bottom' | 'right-bottom' | 'center'
  className?: string
  containerClassName?: string
}

export function LimitedFeatureTooltip({
  children,
  limited,
  position = 'left-bottom',
  className,
  containerClassName
}: TooltipProps) {
  const router = useRouter()

  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  let timeoutId: ReturnType<typeof setTimeout>

  const showTooltip = () => {
    clearTimeout(timeoutId)
    setIsVisible(true)
  }

  const hideTooltip = () => {
    timeoutId = setTimeout(() => {
      setIsVisible(false)
    }, 200)
  }

  const positionMapper = {
    'left-bottom': { triangle: 'left-4', card: 'mt-2 -left-4' },
    'right-bottom': {
      triangle: 'right-4',
      card: 'mt-2 -right-4'
    },
    center: {
      triangle: 'left-1/2 -translate-x-1/2',
      card: 'mt-2 left-1/2 -translate-x-1/2'
    }
  }

  return (
    <div
      className={classNames('flex items-center pointer-events-auto', className)}
    >
      {children}

      {limited && (
        <div
          className="relative ml-1 -top-1"
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          <Image width={15} height={15} src={IconStarYellow} alt="business" />

          {isVisible && (
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInUp}
              className={classNames(
                'absolute z-[51] w-[250px]',
                containerClassName,
                positionMapper[position].card
              )}
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            >
              <div
                className={classNames(
                  'absolute w-3 h-3 bg-gray-800 dark:bg-gray transform rotate-45',
                  positionMapper[position].triangle,
                  '-top-1'
                )}
              />

              <div className="px-5 py-4 flex flex-col gap-2 bg-gray-800 dark:bg-gray rounded-md text-white">
                <Paragraph className="!text-black dark:!text-white">
                  {t('limitedFeatureTooltip.description')}
                </Paragraph>
                <div>
                  <Button onClick={() => router.push(paths.plans)}>
                    {t('limitedFeatureTooltip.button')}
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      )}
    </div>
  )
}
