import i18next from 'i18next'
import { ModalWrapper } from 'components/shared/modal/modal-wrapper'
import { RiShieldCheckLine } from 'react-icons/ri'
import { useRequestPage } from 'hooks/useRequestPage'

type RequestTypesModalProps = {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function RequestTypesModal({
  modalOpen,
  setModalOpen
}: RequestTypesModalProps) {
  const { defaultTexts } = useRequestPage()

  const texts = defaultTexts.find(it => it.language === i18next.language)?.texts
    .moreInformation

  return (
    <ModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <RiShieldCheckLine className="text-primary" size={22} />,
        title: texts?.title ?? '',
        subtitle: texts?.subTitle ?? ''
      }}
      modalSize="medium"
    >
      <div className="overflow-y-scroll scroll-1 p-9 border max-h-[70vh] border-gray-400 rounded-lg">
        <p className="text-gray-250 text-[16px] leading-6">{texts?.intro}</p>

        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            1. {texts?.dataExistence?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.dataExistence?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            2. {texts?.dataAccess?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.dataAccess?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            3. {texts?.dataSharingInformation?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.dataSharingInformation?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            4. {texts?.dataCorrection?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.dataCorrection?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            5. {texts?.dataAnonymization?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.dataAnonymization?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            6. {texts?.dataRemoval?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.dataRemoval?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            7. {texts?.dataPortability?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.dataPortability?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            8. {texts?.consentRevocation?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.consentRevocation?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            9. {texts?.consentRefusal?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.consentRefusal?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            10. {texts?.automatedDecisionReview?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.automatedDecisionReview?.description}
          </p>
        </div>
        <div>
          <p className="mt-6 mb-3 dark:text-white text-lg leading-6">
            11. {texts?.doNotSellData?.title}
          </p>
          <p className="text-gray-250 text-[16px] leading-6">
            {texts?.doNotSellData?.description}
          </p>
        </div>
      </div>
    </ModalWrapper>
  )
}
