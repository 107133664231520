import { useTranslation } from 'next-i18next'
import React, { useState, useRef, useEffect } from 'react'
import { FaCheck } from 'react-icons/fa6'
import { IoClose } from 'react-icons/io5'
import { H3 } from '../typography'

type Option = {
  name: string
  value: string
}

interface AutoCompleteProps {
  options: Option[]
  onChange: (selectedValues: Option[]) => void
  selected?: Option[]
  label?: string
  containerClasses?: string
}

export function AutoComplete({
  selected = [],
  options,
  onChange,
  label,
  containerClasses
}: AutoCompleteProps) {
  const { t } = useTranslation()

  const [selectedValues, setSelectedValues] = useState<Option[]>(selected)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelectedValues(selected)
  }, [selected])

  const handleSelectChange = (option: Option) => {
    setSelectedValues(prevSelected => {
      const isAlreadySelected = prevSelected.some(o => o.value === option.value)
      const newSelected = isAlreadySelected
        ? prevSelected.filter(o => o.value !== option.value)
        : [...prevSelected, option]
      onChange(newSelected)
      return newSelected
    })
  }

  const handleBadgeRemove = (value: string) => {
    setSelectedValues(prevSelected => {
      const newSelected = prevSelected.filter(o => o.value !== value)
      onChange(newSelected)
      return newSelected
    })
  }

  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleToggleDropdown = () => {
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={containerClasses}>
      {label && <H3 fontWeight="bold">{label}</H3>}
      <div className="relative w-full" ref={dropdownRef}>
        <div
          className={`flex flex-wrap items-center gap-2 p-1.5 border border-gray-300 rounded-md cursor-pointer ${
            isOpen ? 'border-blue-500' : ''
          }`}
          onClick={handleToggleDropdown}
        >
          {selectedValues.map(option => (
            <span
              key={option.value}
              className="flex items-center gap-2 px-2 text-sm text-black bg-primary rounded-md"
            >
              {option.name}
              <button
                onClick={e => {
                  e.stopPropagation()
                  handleBadgeRemove(option.value)
                }}
                className="text-xs text-black bg-primary-400 rounded-full w-3 h-3 flex items-center justify-center"
              >
                <IoClose />
              </button>
            </span>
          ))}

          <input
            type="text"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="flex-1 w-full p-1 text-sm focus:outline-none dark:bg-black"
            placeholder={
              selectedValues.length === 0
                ? t('common.placeholders.multipleSelect')
                : ''
            }
          />
        </div>

        {isOpen && (
          <div className="absolute z-10 mt-2 w-full shadow-xl shadow-gray-400 dark:shadow-black bg-white dark:bg-gray divide-y divide-gray-250 dark:divide-gray-500 rounded-md overflow-auto scroll-1 max-h-60">
            {filteredOptions.length === 0 ? (
              <div className="p-2">{t('common.noOptionsFound')}</div>
            ) : (
              filteredOptions.map(option => {
                const isSelected = selectedValues.some(
                  selected => selected.value === option.value
                )

                return (
                  <div
                    key={option.value}
                    className={`${
                      isSelected && 'bg-primary !text-black'
                    } p-2 dark:text-primary cursor-pointer hover:bg-primary hover:!text-black flex items-center justify-between`}
                    onClick={() => handleSelectChange(option)}
                  >
                    {option.name}
                    {isSelected && <FaCheck />}
                  </div>
                )
              })
            )}
          </div>
        )}
      </div>
    </div>
  )
}
