import { Button } from 'components/shared/buttons'
import { Select } from 'components/shared/select/select'
import { useRequestPage } from 'hooks/useRequestPage'
import { FormData } from 'models/request-page'
import { useState } from 'react'
// import defaultTexts from 'util/defaultTexts/requestPageTexts.json'

type PreviewProps = {
  formData: FormData
  selectedLanguage: string
  selectedLegislation: string
}

export function Preview({
  formData,
  selectedLanguage,
  selectedLegislation
}: PreviewProps) {
  const { defaultTexts } = useRequestPage()

  const [requests, setRequests] = useState<string[]>([])
  const [selectedCountry, setSelectedCountry] = useState('')

  function handleRequests(request: string) {
    setRequests(prev =>
      prev?.includes(request)
        ? prev.filter(req => req !== request)
        : [...prev, request]
    )
  }

  return (
    <div
      style={{
        backgroundColor: formData.style.colors.form,
        padding: `${formData.style.margins.vertically}px ${formData.style.margins.horizontally}px`
      }}
      className="w-full rounded-lg py-8 px-6 space-y-4 relative overflow-hidden label-preview"
    >
      <h1
        className="text-2xl text-center"
        style={{
          color: formData.style.colors.title,
          fontFamily: formData.style.fonts.title.family,
          fontSize: formData.style.fonts.title.size,
          lineHeight: `${formData.style.fonts.title.lineHeight}px`
        }}
      >
        {formData.texts.find(ln => ln.language === selectedLanguage)?.title}
      </h1>
      <p
        style={{
          color: formData.style.colors.texts,
          fontFamily: formData.style.fonts.texts.family,
          fontSize: formData.style.fonts.texts.size,
          lineHeight: `${formData.style.fonts.texts.lineHeight}px`
        }}
      >
        {
          formData.texts.find(ln => ln.language === selectedLanguage)
            ?.introduction
        }
      </p>
      {Object.values(formData.documents).filter(Boolean).length > 0 && (
        <div>
          <h3
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          >
            {
              formData.texts.find(ln => ln.language === selectedLanguage)
                ?.docsTitle
            }
          </h3>

          <ul className="list-disc px-8 py-2">
            {formData.documents.privacyMasterId && (
              <li
                className="underline py-1"
                style={{
                  color: formData.style.colors.button,
                  fontFamily: formData.style.fonts.texts.family,
                  fontSize: formData.style.fonts.texts.size,
                  lineHeight: `${formData.style.fonts.texts.lineHeight}px`
                }}
              >
                {
                  defaultTexts.find(it => it.language === selectedLanguage)
                    ?.texts.documents.privacy
                }
              </li>
            )}
            {formData.documents.cookiesMasterId && (
              <li
                className="underline  cursor-pointer py-1"
                style={{
                  color: formData.style.colors.button,
                  fontFamily: formData.style.fonts.texts.family,
                  fontSize: formData.style.fonts.texts.size,
                  lineHeight: `${formData.style.fonts.texts.lineHeight}px`
                }}
              >
                {
                  defaultTexts.find(it => it.language === selectedLanguage)
                    ?.texts.documents.cookies
                }
              </li>
            )}
            {formData.documents.termsMasterId && (
              <li
                className="underline cursor-pointer py-1"
                style={{
                  color: formData.style.colors.button,
                  fontFamily: formData.style.fonts.texts.family,
                  fontSize: formData.style.fonts.texts.size,
                  lineHeight: `${formData.style.fonts.texts.lineHeight}px`
                }}
              >
                {
                  defaultTexts.find(it => it.language === selectedLanguage)
                    ?.texts.documents.terms
                }
              </li>
            )}
          </ul>
        </div>
      )}
      <p
        style={{
          color: formData.style.colors.texts,
          fontFamily: formData.style.fonts.texts.family,
          fontSize: formData.style.fonts.texts.size,
          lineHeight: `${formData.style.fonts.texts.lineHeight}px`
        }}
      >
        {
          defaultTexts.find(it => it.language === selectedLanguage)?.texts
            .requests.select
        }
        *
      </p>
      <div className="rounded-lg border border-gray-400 p-3 flex flex-wrap gap-4 items-center">
        {formData.requests
          .find(req => req.legislation === selectedLegislation)
          ?.requests.map((item, index) => (
            <button
              key={index}
              className="rounded-md px-2 duration-200"
              style={{
                backgroundColor: requests?.includes(item)
                  ? formData.style.colors.button
                  : `${formData.style.colors.texts}1a`,
                color: requests?.includes(item)
                  ? formData.style.colors.buttonText
                  : formData.style.colors.texts,
                fontFamily: formData.style.fonts.button.family,
                fontSize: formData.style.fonts.button.size,
                lineHeight: `${formData.style.fonts.button.lineHeight}px`,
                borderRadius: formData.style.rounded.button
              }}
              onClick={() => handleRequests(item)}
            >
              {
                (
                  defaultTexts.find(it => it.language === selectedLanguage)
                    ?.texts.requests as { [key: string]: any }
                )[item]
              }
            </button>
          ))}
      </div>

      <div>
        <label
          htmlFor="email"
          className="font-bold mb-1"
          style={{
            color: formData.style.colors.texts,
            fontFamily: formData.style.fonts.texts.family,
            fontSize: formData.style.fonts.texts.size,
            lineHeight: `${formData.style.fonts.texts.lineHeight}px`
          }}
        >
          {
            defaultTexts.find(it => it.language === selectedLanguage)?.texts
              .fields.email.name
          }
          *
        </label>
        <input
          id="email"
          maxLength={50}
          placeholder={
            defaultTexts.find(it => it.language === selectedLanguage)?.texts
              .fields.email.placeholder
          }
          className="bg-transparent focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 h-[40px] box-border rounded-md  border border-gray-400"
          style={{
            color: formData.style.colors.texts,
            fontFamily: formData.style.fonts.texts.family,
            fontSize: formData.style.fonts.texts.size,
            lineHeight: `${formData.style.fonts.texts.lineHeight}px`
          }}
        />
      </div>
      {formData.extraFields?.includes('name') && (
        <div>
          <label
            className="font-bold mb-1"
            htmlFor="name"
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          >
            {
              defaultTexts.find(it => it.language === selectedLanguage)?.texts
                .fields.name.name
            }
            *
          </label>
          <input
            id="name"
            maxLength={50}
            placeholder={
              defaultTexts.find(it => it.language === selectedLanguage)?.texts
                .fields.name.placeholder
            }
            className="bg-transparent focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 h-[40px] box-border rounded-md border border-gray-400"
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          />
        </div>
      )}
      {formData.extraFields?.includes('country') && (
        <div>
          <label
            className="font-bold mb-1"
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          >
            {
              defaultTexts.find(it => it.language === selectedLanguage)?.texts
                .fields.country.name
            }
            *
          </label>
          <Select
            containerClasses={'w-full'}
            backgroundColor="!bg-transparent"
            textColor={formData.style.colors.texts}
            params={
              defaultTexts
                .find(it => it.language === selectedLanguage)
                ?.texts.fields.country.options.map(it => ({
                  name: it.value,
                  value: it.key
                })) ?? []
            }
            value={selectedCountry}
            onChange={e => setSelectedCountry(e.value as string)}
          />
        </div>
      )}
      {formData.extraFields?.includes('obs') && (
        <div>
          <label
            className="font-bold mb-1"
            htmlFor={'obs'}
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          >
            {
              defaultTexts.find(it => it.language === selectedLanguage)?.texts
                .fields.obs.name
            }
            *
            <span className="opacity-40 pl-1">
              {
                defaultTexts.find(it => it.language === selectedLanguage)?.texts
                  .optional
              }
            </span>
          </label>
          <textarea
            id="obs"
            maxLength={250}
            rows={5}
            className="bg-transparent focus:outline-none dark:text-white resize-none scroll-1 appearance-none relative block w-full px-3 py-2 box-border rounded-md border border-gray-400"
            style={{
              color: formData.style.colors.texts,
              fontFamily: formData.style.fonts.texts.family,
              fontSize: formData.style.fonts.texts.size,
              lineHeight: `${formData.style.fonts.texts.lineHeight}px`
            }}
          />
        </div>
      )}

      <div className="flex justify-center">
        <Button
          style={{
            backgroundColor: formData.style.colors.button,
            color: formData.style.colors.buttonText,
            fontFamily: formData.style.fonts.button.family,
            fontSize: formData.style.fonts.button.size,
            lineHeight: `${formData.style.fonts.button.lineHeight}px`,
            borderRadius: formData.style.rounded.button
          }}
        >
          {
            defaultTexts.find(it => it.language === selectedLanguage)?.texts
              .buttons.send
          }
        </Button>
      </div>
    </div>
  )
}
