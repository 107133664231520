import { Blur } from '../blur/blur'
import { BodyText } from '../typography'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import { useTranslation } from 'next-i18next'
import { ElementType } from 'react'
import { HiOutlineThumbDown, HiOutlineThumbUp } from 'react-icons/hi'
import { MdCloudDownload } from 'react-icons/md'
import { LuConstruction } from 'react-icons/lu'

type IconType = 'download' | 'optins' | 'optouts' | 'uniqueUsers' | 'rejectAll'

interface Props {
  type: IconType
  quantity: string | number
  shouldNotShow: boolean
}

export function DashboardMetricsCard({ type, quantity, shouldNotShow }: Props) {
  const { t } = useTranslation()

  const icons: Record<
    IconType,
    { icon: ElementType; color: string; text: string }
  > = {
    download: {
      icon: MdCloudDownload,
      color: 'bg-yellow',
      text: t('org.dashboard.downloads')
    },
    optins: {
      icon: HiOutlineThumbUp,
      color: 'bg-purple',
      text: t('org.dashboard.optins')
    },
    optouts: {
      icon: HiOutlineThumbDown,
      color: 'bg-pink',
      text: t('org.dashboard.optouts')
    },
    uniqueUsers: {
      icon: BsFillPersonCheckFill,
      color: 'bg-primary',
      text: t('org.dashboard.uniqueUsersDP')
    },
    rejectAll: {
      icon: LuConstruction,
      color: 'bg-orange',
      text: t('org.dashboard.rejectAll')
    }
  }

  const IconComponent = icons[type].icon

  return (
    <div
      className={`relative overflow-hidden rounded-md flex-1 p-5 flex flex-col justify-between ${icons[type].color}`}
    >
      <Blur shouldNotShow={shouldNotShow} />
      <IconComponent
        size={120}
        className="absolute -right-[50px] top-1/2 transform -translate-y-1/2 opacity-[0.1]"
      />
      <div className="relative z-[2]">
        <BodyText
          fontWeight="bold"
          className="dark:!text-gray text-xl text-center"
        >
          {quantity}
        </BodyText>
        <BodyText
          fontWeight="bold"
          className="dark:!text-gray text-base text-center"
        >
          {icons[type].text}
        </BodyText>
        <BodyText className="dark:!text-gray text-sm text-center">
          {t('org.dashboard.duringPeriod')}
        </BodyText>
      </div>
    </div>
  )
}
