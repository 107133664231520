import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'
import { classNames } from 'util/shared'

interface SearchProps {
  onInputChange: (value: string) => void
  placeholder?: string
  disabled?: boolean
}

export function SearchWithoutButton({
  onInputChange,
  placeholder,
  disabled = false
}: SearchProps) {
  const [searchItem, setSearchItem] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className="md:flex items-center relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <BiSearch
          className={classNames(
            'h-5 w-5',
            isFocused || searchItem ? 'text-primary' : 'text-gray-400',
            disabled && 'dark:text-gray-500 text-gray-75'
          )}
          aria-hidden="true"
        />
      </div>
      <input
        type="text"
        name="text"
        id="text"
        value={searchItem}
        onChange={e => {
          setSearchItem(e.target.value)
          onInputChange(e.target.value)
        }}
        disabled={disabled}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={classNames(
          `
            input outline-none border border-gray-100 focus:border-primary block w-80 pl-10 h-10 sm:text-sm pr-6 rounded 
            focus:bg-white-200  active:border-primary disabled:border-gray-75 
            disabled:dark:border-gray-500 disabled:dark:!placeholder-gray-500 dark:bg-black dark:border-gray-400  dark:focus:border-primary focus:dark:bg-gray  dark:text-white  dark:active:border-primary  
            `,
          searchItem && 'border-primary dark:bg-gray dark:border-primary'
        )}
        placeholder={placeholder}
      />
      {searchItem.length > 0 && (
        <IoClose
          onClick={() => {
            setSearchItem('')
            onInputChange('')
          }}
          className="absolute right-2 cursor-pointer top-1/2 transform -translate-y-1/2 dark:text-white"
          size={16}
        />
      )}
    </div>
  )
}
