import { Button, SpinLoading } from 'components'
import { ModalWrapper } from './modal-wrapper'
import { useTranslation } from 'next-i18next'
import { IoCopyOutline, IoExitOutline } from 'react-icons/io5'
import toast from 'react-hot-toast'
import { useUserRequests } from 'hooks/useUserRequests'
import { useEffect, useState } from 'react'
import { useHandleError } from 'hooks/useHandleError'
import { RequestDetails } from 'models/user-requests'

interface UserRequestDetailsProps {
  requestId: string
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
}

export function UserRequestDetails({
  requestId,
  modalOpen,
  setModalOpen
}: UserRequestDetailsProps) {
  const { t } = useTranslation()
  const { handleError } = useHandleError()

  const { getUserRequestDetails } = useUserRequests({})

  const [details, setDetails] = useState<RequestDetails>({} as RequestDetails)
  const [loading, setLoading] = useState(false)

  async function getDetails() {
    setLoading(true)

    try {
      const result = await getUserRequestDetails(requestId)
      if (result?.success) {
        const { data }: { data: RequestDetails } = result
        setDetails(data)
      } else {
        handleError(result?.message)
      }
    } catch (error) {
      handleError()
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetails()
  }, [])

  function copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(t('modal.userRequestDetails.copySuccess'))
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <ModalWrapper
      modalSize="medium"
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      headerProps={{
        icon: <IoExitOutline className="text-primary" size={24} />,
        title: t('modal.userRequestDetails.title'),
        subtitle: t('modal.userRequestDetails.subTitle')
      }}
    >
      {loading ? (
        <div className="flex h-[60vh] justify-center items-center">
          <SpinLoading />
        </div>
      ) : (
        <>
          <div className="p-9 rounded-lg border border-gray-400">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t('modal.userRequestDetails.requestType')}
                  </span>
                  <span className="dark:text-white text-base font-bold">
                    {details.requestType || '-'}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t('modal.userRequestDetails.userIp')}
                  </span>
                  <div className="dark:text-white text-base font-bold flex gap-1 items-center">
                    <span className="truncate">{details.ip}</span>
                    <span>
                      <IoCopyOutline
                        onClick={() => copyToClipboard(details.ip)}
                        className="!text-gray-200 cursor-pointer"
                      />
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t('modal.userRequestDetails.originCountry')}
                  </span>
                  <span className="dark:text-white text-base font-bold">
                    {details.country || '-'}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t('modal.userRequestDetails.requestId')}
                  </span>
                  <div className="dark:text-white text-base font-bold flex gap-1 items-center">
                    <span className="truncate">#{details.requestId}</span>
                    <span>
                      <IoCopyOutline
                        onClick={() => copyToClipboard(details.requestId)}
                        className="!text-gray-200 cursor-pointer"
                      />
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t('modal.userRequestDetails.userEmail')}
                  </span>
                  <div className="dark:text-white text-base font-bold flex gap-1 items-center">
                    <span className="truncate">{details.email}</span>
                    <span>
                      <IoCopyOutline
                        onClick={() => copyToClipboard(details.email)}
                        className="!text-gray-200 cursor-pointer"
                      />
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t('modal.userRequestDetails.originUrl')}
                  </span>
                  <span className="dark:text-white text-base font-bold">
                    {details.urlFrom || '-'}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t('modal.userRequestDetails.navigatorLanguage')}
                  </span>
                  <span className="dark:text-white text-base font-bold">
                    {details.userLanguage || '-'}
                  </span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              {details?.extraFields?.map(it => (
                <div className="flex flex-col">
                  <span className="text-gray-300 text-xs">
                    {t(`modal.userRequestDetails.${it.fieldId}`)}
                  </span>
                  <span className="dark:text-white text-base font-bold">
                    {it.value || '-'}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-center gap-4 mt-10">
            {/* <Button iconLeft={TbExternalLink} buttonType={ButtonType.Secondary}>
              {t('modal.userRequestDetails.export')}
            </Button> */}
            <Button onClick={() => setModalOpen(false)}>
              {t('modal.userRequestDetails.close')}
            </Button>
          </div>
        </>
      )}
    </ModalWrapper>
  )
}
