import { BodyText, H1 } from '../typography'

type TotalNumberProps = {
  loading: boolean
  label: string
  value: string | number
}

export function TotalNumber({ loading, label, value }: TotalNumberProps) {
  return (
    <div className="py-6 px-10 bg-white-200 dark:bg-gray rounded-lg flex-1">
      <BodyText className="!text-primary">{label}</BodyText>
      {loading ? (
        <div className="animate-pulse bg-gray-75 dark:bg-black w-full h-12 mt-2 rounded-sm"></div>
      ) : (
        <H1
          fontWeight="bold"
          className="text-[40px] text-gray-300 dark:text-white"
        >
          {value}
        </H1>
      )}
    </div>
  )
}
