export const paths = {
  support: {
    documents: 'https://goadopt.io/support',
    ticket: 'https://adopt-help.freshdesk.com/support/tickets/new'
  },
  documentation: {
    style:
      'https://goadopt.io/support/config-opcionais/customizacoes-do-aviso/editar-texto-do-aviso-adopt/',
    identityProvider: 'https://goadopt.io/support/docs/sso/azure-ad/',
    blockTags:
      'https://goadopt.io/support/settings/bloqueio/bloqueio-tags-e-cookies-google-tag-manager/',
    darkLightTheme:
      'https://goadopt.io/support/docs/classes-customization/api/',
    cache: 'https://goadopt.io/support/faq/erro/cache/'
  },
  tutorials: {
    style: 'https://www.youtube.com/watch?v=Rmq8abTW_YU'
  },
  paymentMethods: '/payment-methods',
  passwordRecovery: '/password-recovery',
  plans: '/plans',
  referrals: '/referrals',
  plan: '/plan',
  login: '/login',
  organizations: '/organizations',
  globalDashboard: '/global-dashboard',
  webhooks: '/webhooks',
  accountConfiguration: '/configuration',
  org: {
    disclaimers: '/org/:organizationPathname/disclaimers',
    disclaimer: {
      config: '/org/:organizationPathname/disclaimer/:disclaimerPathname',
      versionConfig:
        '/org/:organizationPathname/disclaimer/:disclaimerPathname/version-config',
      style: '/org/:organizationPathname/disclaimer/:disclaimerPathname/style',
      documents:
        '/org/:organizationPathname/disclaimer/:disclaimerPathname/documents',
      optOut:
        '/org/:organizationPathname/disclaimer/:disclaimerPathname/opt-out',
      installation:
        '/org/:organizationPathname/disclaimer/:disclaimerPathname/installation',
      tags: '/org/:organizationPathname/disclaimer/:disclaimerPathname/tags',
      changelog:
        '/org/:organizationPathname/disclaimer/:disclaimerPathname/tags/changelog',
      privacyPage: {
        versions: (organizationPathname: string, disclaimerPathname: string) =>
          `/org/${organizationPathname}/disclaimer/${disclaimerPathname}/privacy-page/versions`
      }
    },
    privacyPortal: {
      index: (organizationPathname: string) =>
        `/org/${organizationPathname}/privacy-portal`,
      requestPages: (organizationPathname: string) =>
        `/org/${organizationPathname}/privacy-portal/request-pages`,
      requestPage: (organizationPathname: string, requestPageId: string) =>
        `/org/${organizationPathname}/privacy-portal/request-pages/${requestPageId}`,
      requestPageVersionHistory: (
        organizationPathname: string,
        requestPageId: string
      ) =>
        `/org/${organizationPathname}/privacy-portal/request-pages/${requestPageId}/versions`
    }
  },
  signup: {
    register: '/register',
    disclaimerDomain: '/register/disclaimer-domain',
    disclaimerInstallation: '/register/disclaimer-installation'
  },
  sso: {
    register: '/sso/register',
    login: '/sso/login'
  },
  cs: {
    pedro: 'https://calendly.com/pedroadopt/30',
    fernanda: 'https://calendly.com/adopt-business/30min'
  }
}
