import { gql } from '@apollo/client'

export const GET_USER_REQUESTS = gql`
  query getUserRequests(
    $offset: Int!
    $where: requests_page_user_request_bool_exp
  ) {
    requests_page_user_request(
      where: $where
      offset: $offset
      order_by: { created_at: desc }
      limit: 10
    ) {
      id
      status
      created_at
      event_type_id
      email_masked
    }
    requests_page_user_request_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GET_USER_REQUESTS_METRICS = gql`
  mutation GetUserRequestsMetrics(
    $organizationId: String
    $startDate: String
    $endDate: String
  ) {
    get_opt_out_metrics(
      object: {
        organizationId: $organizationId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      success
      message
      data {
        totalRequests
        newRequests
        processingRequests
        finishedRequests
      }
      errorCode
      errorDetails
    }
  }
`

export const GET_USER_REQUEST_DETAILS = gql`
  mutation GetUserRequestDetails($requestId: String!) {
    get_request_details(object: { requestId: $requestId }) {
      success
      message
      data {
        requestType
        requestId
        requestPageMasterId
        email
        ip
        urlFrom
        country
        userLanguage
        legislation
        extraFields {
          fieldId
          value
        }
      }
      errorCode
      errorDetails
    }
  }
`

export const SET_USER_REQUEST_STATUS = gql`
  mutation SetUserRequestStatus($requestIds: [String!], $newStatus: String!) {
    set_user_request_status(
      object: { requestIds: $requestIds, newStatus: $newStatus }
    ) {
      success
      message
      errorCode
      errorDetails
    }
  }
`
